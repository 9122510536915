<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <h3>Privacy Policy</h3>
        <p>
          Just so you know, we use your Personal Information ONLY for providing and improving the
          Site. By using the Site, you agree to the collection and use of information in accordance
          with this policy. We do not sell or share this information with anyone else. Unless
          otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same
          meanings as in our Terms and Conditions, accessible at www.GraceWayRadio.com.
        </p>
        <h4>Information Collection And Use</h4>
        <p>
          While using our Site, we may ask you to provide us with certain personally identifiable
          information that can be used to contact or identify you. Personally identifiable
          information may include, but is not limited to, your name, email address, postal address
          and phone number (“Personal Information”).
        </p>
        <h4>Log Data</h4>
        <p>
          Like many site operators, we collect information that your browser sends whenever you
          visit our Site (“Log Data”). This Log Data may include information such as your computer’s
          Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site
          that you visit, the time and date of your visit, the time spent on those pages and other
          statistics.
        </p>
        <h4>Cookies</h4>
        <p>
          Cookies are files with small amount of data, which may include an anonymous unique
          identifier. Cookies are sent to your browser from a web site and stored on your computer’s
          hard drive. Like many sites, we use “cookies” to collect information. You can instruct
          your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
          you do not accept cookies, you may not be able to use some portions of our Site.
        </p>
        <h4>Security</h4>
        <p>
          The security of your Personal Information is important to us but remember that no method
          of transmission over the Internet, or method of electronic storage, is 100% secure. While
          we strive to use commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <h4>Links To Other Sites</h4>
        <p>
          Our Site may contain links to other sites that are not operated by us. If you click on a
          third party link, you will be directed to that third party’s site. We strongly advise you
          to review the Privacy Policy of every site you visit. GraceWay Radio has no control over,
          and assumes no responsibility for, the content, privacy policies, or practices of any
          third-party sites or services.
        </p>
        <h4>Changes To This Privacy Policy</h4>
        <p>
          GraceWay Radio may update this Privacy Policy from time to time. We will notify you of any
          changes by posting the new Privacy Policy on the Site. You are advised to review this
          Privacy Policy periodically for any changes.
        </p>
        <h4>Contact Us</h4>
        <p>If you have any questions about this Privacy Policy, please 
          <a href="mailto:admin@gracewayradio.com">contact us.</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo() {
    return {
      title: "Privacy Policy",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "We do not sell or share this information with anyone else. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at www.GraceWayRadio.com."
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "We do not sell or share this information with anyone else. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at www.GraceWayRadio.com."
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Privacy Policy" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/privacy-policy"
        }
      ]
    };
  },
}
</script>